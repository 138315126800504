import React, { Component } from 'react';

export default class PodcastHomeItem extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
<div className="max-w-sm w-full lg:max-w-full lg:flex p-8">
  <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
        style={{ backgroundImage: `url('${this.props.podcast.thumbnail}')` }}
        title="Podcast Artwork">
  </div>
  <div className="grow bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow">
    <div className="mb-8">
      <div className="text-gray-900 font-bold text-xl mb-2">{this.props.podcast.title}</div>
      <p className="text-gray-700 text-base">{this.props.podcast.description}</p>
    </div>
    <div className="flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
      <div className="text-sm m-2">
        <p className="text-gray-900 leading-none">{this.props.podcast.artist}</p>
        <p className="text-gray-600">{this.props.podcast.published}</p>
      </div>
    </div>
  </div>
</div>
    );
  }
}
