import { config } from './Constants';
import React, { Component } from 'react';
import PodcastHomeItem from './PodcastHomeItem';
import Spinner from './Spinner';

export default class PodcastHomeList extends Component {
  constructor(props) {
    super(props)

    this.state = {
       error: null,
       isLoaded: false,
       podcasts: []
    }
  }

  componentDidMount() {
    fetch(`${config.url.API_URL}/api/podcasts?page=${this.props.currentPage}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            podcasts: result.data
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, podcasts } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Spinner/>;
    } else {
      return (
        <ul className="flex flex-wrap">
          {podcasts.map(podcast => (
            <li key={podcast.id} className="w-1/2">
              <PodcastHomeItem podcast={podcast}/>
            </li>
          ))}
        </ul>
      );
    }
  }
}
