
import React, { Component } from 'react';
import SponsoredEpisodes from './SponsoredEpisodes';
import Pagination from './Pagination';
import Footer from './Footer';

export class SponsorStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };

    this.onPageChanged = this.onPageChanged.bind(this);
  }

  onPageChanged(page) {
    this.setState({currentPage: page});
  }

  render() {
    return (
      <div className='my-2 flex items-center justify-center flex-col'>
        <SponsoredEpisodes currentPage={this.state.currentPage} key={this.state.currentPage}/>
        <Pagination onPageChanged={this.onPageChanged} totalPagePath="total-unique-sponsor-pages"/>
        <Footer/>
      </div>
    )
  }
};

export default SponsorStats;