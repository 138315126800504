import logo from './logo.svg';
import './App.css';
import PodcastHomeList from './PodcastHomeList';
import Pagination from './Pagination';

import React, { Component } from 'react';
import PodcastIndex from './PodcastIndex';
import Nav from './Nav';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import SponsorStats from './SponsorStats';

export default class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={ <div><Nav/><PodcastIndex/> </div>}/>
          <Route path="/sponsors" element={ <div><Nav/><SponsorStats/> </div>}/>
        </Routes>
      </Router>
      );
  }
}
