import { config } from './Constants';
import React, { Component } from 'react';
import PaginationPage from './PaginationPage';

export class Pagination extends Component {
  constructor(props) {
    super(props)

    this.state = {
       error: null,
       totalPages: 1,
       currentPage: 1
    }
  }

  handlePageClicked = (page) => {
    this.props.onPageChanged(page);

    const currentState = this.state;
    currentState.currentPage = page;
    this.setState(currentState);
  }

  componentDidMount() {
    fetch(`${config.url.API_URL}/api/${this.props.totalPagePath}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            totalPages: result.totalPages,
            currentPage: 1
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const currentPage = this.state.currentPage;

    const start =
      currentPage > this.props.pageThreshold
      ? currentPage - (this.props.totalPagesToShow / 2)
      : 1;
    const end =
      currentPage > this.props.pageThreshold
      ?  currentPage + (this.props.totalPagesToShow / 2)
      : this.props.totalPagesToShow;

    let pages = [];
    for (let i = start; i <= end; ++i) {
      pages.push(i);
    }
    return (
    <div>
      <ul className="flex items-center justify-center mt-1">
      {pages.map((p, i) => {
        if ( this.state.currentPage !== p )
          return (
            <PaginationPage key={p} page={p} onPageClick={this.handlePageClicked}>
              <li className="m-2" key={p}>{p}</li>
            </PaginationPage>
          )
        else
        return (
            <PaginationPage key={p} page={p} onPageClick={this.handlePageClicked}>
              <li className="m-2 text-blue-500 font-bold" key={p}>{p}</li>
            </PaginationPage>
          )
      }
      )}
      </ul>
    </div>
    );
  }
}

Pagination.defaultProps = {
  currentPage: 1,
  totalPagesToShow: 10,
  pageThreshold: 5
}

export default Pagination;
