import React, { Component } from 'react';
import { config } from './Constants';


export class SponsoredEpisodes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    fetch(`${config.url.API_URL}/api/sponsors?page=${this.props.currentPage}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, data } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading sponsor stats...</div>;
    } else {
      return (
        <div>
          <table className="mx-4 shadow-md rounded table-fixed">
            <thead className='bg-gray-50'>
              <tr>
                <th className='w-1/2 p-5 text-left font-bold'>Sponsor</th>
                <th className='w-1/2 p-5 text-left font-bold'>Episodes</th>
              </tr>
            </thead>
            <tbody>
              {data.map(sponsor => (
                <tr key={sponsor.descr} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white'>{sponsor.descr}</td>
                  <td className='py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white'>{sponsor.spcount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default SponsoredEpisodes;