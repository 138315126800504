
import React, { useState } from 'react';

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="mx-4">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen &&
        <MobileMenu>
          {navLinks}
        </MobileMenu>}
      <hr/>
    </div>
  );
};

export default Nav;

const SpeakerSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
</svg>
);

const pages = [
    {
      title: 'Sponsor Stats',
      href: 'sponsors'
    }];
const navLinks =
  pages.map(page =>
    <a key={page.href} className="no-underline text-gray-800 font-semibold hover:text-gray-600" href={`${page.href}`}>{page.title}</a>);

const Navbar = ({ menuOpen, setMenuOpen }) => (
  <div className="flex items-center p-4">
    <div className="flex items-center">
      <SpeakerSvg/>
      <a href="/" className="text-xl font-bold no-underline text-gray-800 hover:text-gray-600 mx-2">podcastregister.com</a>
    </div>
    <nav className="hidden md:block space-x-6 mx-6">
      {navLinks}
    </nav>
    <button type="button" aria-label="Toggle mobile menu" onClick={() => setMenuOpen(!menuOpen)} className="rounded md:hidden focus:outline-none focus:ring focus:ring-gray-500 focus:ring-opacity-50"><MenuAlt4Svg menuOpen={menuOpen} /></button>
  </div>
);

const MobileMenu = ({ children }) => (
  <nav className="p-4 flex flex-col space-y-3 md:hidden">
    {children}
  </nav>
);


const MenuAlt4Svg = ({ menuOpen }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={`transition duration-100 ease h-8 w-8 ${menuOpen ? 'transform rotate-90' : ''}`} viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M3 7a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 13a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
  </svg>
);
