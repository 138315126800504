import React from 'react';

function Footer() {
  return (
    <div className='flex flex-col text-center my-2'>
      <hr/>
      <p className='my-1'>A podcast index built by <a href='https://www.snowlinesoftware.com'>Snow Line Software</a> powered by <a href='htts://reactjs.org'>React</a> and <a href='https://clojure.org'>Clojure</a></p>
    </div>
  );
}

export default Footer;