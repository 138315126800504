import PodcastHomeList from './PodcastHomeList';
import Pagination from './Pagination';

import React, { Component } from 'react';
import Footer from './Footer';

export default class PodcastIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
       currentPage: 1
    }

    this.onPageChanged = this.onPageChanged.bind(this);
  }

  onPageChanged(page) {
    this.setState({ currentPage: page });
  }

  render() {
    return (
    <div className="text-gray-800 font-sans">
      <PodcastHomeList className="flex items-center"
        key={this.state.currentPage}
        currentPage={this.state.currentPage}/>
      <Pagination onPageChanged={this.onPageChanged} totalPagePath="total-pages"/>
      <Footer/>
    </div>
  );
  }
}
